// EventManager.js

import React from 'react';
import EventList from './EventList';

function EventManager({ events, addEvent, updateEvent, deleteEvent }) {
  return (
    <div className="section">
      <h2>Event Details</h2>
      <EventList
        events={events}
        onAddEvent={addEvent}
        onUpdateEvent={updateEvent}
        onDeleteEvent={deleteEvent}
      />
    </div>
  );
}

export default EventManager;
