// EventItem.js

import React, { useState } from 'react';
import EventForm from './EventForm';
import apiService from '../services/apiService';

function EventItem({ event, onUpdateEvent, onDeleteEvent }) {
  const [isEditing, setIsEditing] = useState(false);
  const [showParserCode, setShowParserCode] = useState(false);
  const [parserCode, setParserCode] = useState(null);
  const [showHtmlContent, setShowHtmlContent] = useState(false);
  const [showAbout, setShowAbout] = useState(false);

  const handleEdit = () => setIsEditing(true);
  const handleCancelEdit = () => setIsEditing(false);

  const handleUpdateEvent = async (updatedEvent) => {
    await onUpdateEvent(event.id, updatedEvent);
    setIsEditing(false);
  };

  const handleDeleteEvent = async () => {
    await onDeleteEvent(event.id);
  };

  const toggleParserCode = async () => {
    if (!showParserCode) {
      try {
        const hostname = new URL(event.origin_url).hostname;
        const code = await apiService.getParserCode(hostname);
        setParserCode(code);
      } catch (error) {
        console.error('Error fetching parser code:', error);
      }
    }
    setShowParserCode(!showParserCode);
  };

  const toggleHtmlContent = () => {
    setShowHtmlContent(!showHtmlContent);
  };

  const toggleAbout = () => { 
    setShowAbout(!showAbout);
  }

  return (
    <div className="event-item">
      {isEditing ? (
        <EventForm
          event={event}
          onUpdateEvent={handleUpdateEvent}
          onCancel={handleCancelEdit}
        />
      ) : (
        <div className='event-wrapper'>
                      <div className="event-details">
                <div><strong>Title:</strong> {event.title}</div>
                <div><strong>Start Time:</strong> {event.start_time}</div>
                <div><strong>End Time:</strong> {event.end_time}</div>
                <div><strong>Date:</strong> {event.date}</div>
                <div>
                    <strong>About:</strong>
                    <button onClick={toggleAbout}>
                        {showAbout ? 'Hide' : 'Show'}
                    </button>
                    {showAbout && <p>{event.about}</p>}

                </div>
                <div><strong>URL:</strong> 
                    <a href={event.url} target="_blank" rel="noopener noreferrer">
                    {event.url}
                    </a>
                </div>
                <div><strong>Origin URL:</strong> 
                    <a href={event.origin_url} target="_blank" rel="noopener noreferrer">
                    {event.origin_url}
                    </a>
                </div>
                <div><strong>Registration Link:</strong> 
                    <a href={event.registration_link} target="_blank" rel="noopener noreferrer">
                    {event.registration_link}
                    </a>
                </div>
            </div>
          {/* Add other event details as needed */}
          <div className="event-actions">
          <button onClick={handleEdit}>Edit</button>
          <button onClick={handleDeleteEvent}>Delete</button>
          <button onClick={toggleParserCode}>
            {showParserCode ? 'Hide Parser Code' : 'Show Parser Code'}
          </button>
          <button onClick={toggleHtmlContent}>
            {showHtmlContent ? 'Hide HTML Content' : 'Show HTML Content'}
          </button>

          </div>
          {showParserCode && parserCode && (
            <div className="parser-code">
              <textarea>{parserCode}</textarea>
            </div>
          )}
          {showHtmlContent && event.html_content && (
            <div className="html-content">
              <textarea>{event.html_content}</textarea>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default EventItem;
