// components/BaseUrlItem.js

import React, { useState, useEffect } from 'react';
import BaseUrlForm from './BaseUrlForm';
import EventUrlManager from './EventUrlManager';
import EventManager from './EventManager';
import useEvents from '../hooks/useEvents';

function BaseUrlItem({ baseUrl, onSelectBaseUrl, onUpdateBaseUrl, onDeleteBaseUrl }) {
  const [isEditing, setIsEditing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [eventCount, setEventCount] = useState(0);
  const [showHtmlContent, setShowHtmlContent] = useState(false);
  const { events, fetchEventsByBaseUrl, addEvent, updateEvent, deleteEvent } = useEvents();

  useEffect(() => {
    // Fetch event count for the base URL once when the component mounts
    const fetchEventCount = async () => {
      try {
        const count = await fetchEventsByBaseUrl(baseUrl.url, true); // Pass true to get count only
        setEventCount(count);
      } catch (error) {
        console.error(`Error fetching event count for base URL ${baseUrl.url}:`, error);
      }
    };
    fetchEventCount();
  }, [baseUrl.url]);

  const handleExpand = () => {
    setExpanded(!expanded); // Toggle the expanded state
  };

  const handleSelect = () => {
    setExpanded(!expanded); // Toggle the expanded state
    onSelectBaseUrl(baseUrl.url);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleUpdate = (updatedBaseUrl) => {
    onUpdateBaseUrl(baseUrl.id, updatedBaseUrl);
    setIsEditing(false);
  };

  const handleDelete = () => {
    onDeleteBaseUrl(baseUrl.id);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const toggleHtmlContent = () => {
    setShowHtmlContent(!showHtmlContent);
  };

  return (
    <div className="base-url-item">
      {isEditing ? (
        <BaseUrlForm
          baseUrl={baseUrl}
          onUpdateBaseUrl={handleUpdate}
          onCancel={handleCancelEdit}
        />
      ) : (
        <div className="base-url-content">
          <div className="base-url-details">
            <div className="base-url-wrapper">
              {baseUrl.validated && <span className="validated">Validated</span>}
              {!baseUrl.validated && <span className="invalid">Test</span>}
              <a href="#" onClick={handleSelect}>
                {baseUrl.url}
              </a>
              <span>{baseUrl.selector}</span>
              <span>{eventCount} events</span>
            </div>
            <div className="base-url-actions">
              <button onClick={toggleHtmlContent}>
                {showHtmlContent ? 'HideHTML' : 'ShowHTML'}
              </button>
              <button onClick={handleExpand}>{expanded ? 'Collapse' : 'Expand'}</button>
              <button onClick={handleEdit}>Edit</button>
              <button onClick={handleDelete}>Delete</button>
            </div>
          </div>
          {showHtmlContent && baseUrl.html_content && (
            <div className="html-content">
              <textarea>{baseUrl.html_content}</textarea>
            </div>
          )}
          {showHtmlContent && !baseUrl.html_content && (
            <div className="html-content">HTML content not available</div>
          )}
          <div className="base-url-events" hidden={!expanded}>
            <EventUrlManager
              baseUrl={baseUrl.url}
              onEventScraped={(eventData) => addEvent(eventData, true)}
            />
            <EventManager
              events={events}
              addEvent={addEvent}
              updateEvent={updateEvent}
              deleteEvent={deleteEvent}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default BaseUrlItem;
