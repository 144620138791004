// components/BaseUrlList.js

import React from 'react';
import BaseUrlItem from './BaseUrlItem';

function BaseUrlList({ baseUrls, onSelectBaseUrl, onUpdateBaseUrl, onDeleteBaseUrl }) {
  return (
    <div className="base-url-list">
      {baseUrls.map((baseUrl) => (
        <BaseUrlItem
          key={baseUrl.id}
          baseUrl={baseUrl}
          onSelectBaseUrl={onSelectBaseUrl}
          onUpdateBaseUrl={onUpdateBaseUrl}
          onDeleteBaseUrl={onDeleteBaseUrl}
        />
      ))}
    </div>
  );
}

export default BaseUrlList;
