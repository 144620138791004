// components/EventUrlManager.js

import React, { useState } from 'react';
import useEventUrls from '../hooks/useEventUrls';
import EventUrlActions from './EventUrlActions';

function EventUrlManager({ baseUrl, onEventScraped }) {
  const { eventUrls, fetchEventUrls, deleteEventUrls } = useEventUrls();
  const [loading, setLoading] = useState(false);

  const handleFetchEventUrls = async () => {
    if (eventUrls.length > 0) return; // Skip fetching if URLs are already loaded
    setLoading(true); // Set loading to true before fetching
    await fetchEventUrls(baseUrl);
    setLoading(false); // Set loading to false after fetching
  };

  return (
    <div className="section">
      <h2>Event URLs for {baseUrl}</h2>
      <button onClick={handleFetchEventUrls} disabled={loading}>
        {loading ? 'Loading...' : 'Fetch Event URLs'}
      </button>
      {loading && <p>Loading event URLs, please wait...</p>}
      <EventUrlActions
        eventUrls={eventUrls}
        baseUrl={baseUrl}
        onEventScraped={onEventScraped}
        onDeleteEventUrls={deleteEventUrls}
      />
    </div>
  );
}

export default EventUrlManager;
