// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CalendarPage from './components/CalendarPage';
import BaseUrlManager from './components/BaseUrlManager';
import './App.css'; // Ensure this is correctly imported

function App() {
  return (
    <Router>
      <div className="App"> {/* Wrapper with className "App" */}
        <Routes>
          <Route path="/calendar" element={<CalendarPage />} />
          <Route path="/" element={<BaseUrlManager />} />
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
