// components/BigCalendarPage.js

import React, { useEffect, useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import apiService from '../services/apiService';

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function BigCalendarPage() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const allEvents = await apiService.fetchAllEvents();

        const formattedEvents = allEvents.map((event) => {
          try {
            // Ensure the date is valid
            const eventDate = event.date ? new Date(event.date) : null;
            if (!eventDate || isNaN(eventDate.getTime())) {
              throw new Error('Invalid date');
            }

            // Parse the start time, default to 00:00 if missing or invalid
            const [startHour, startMinute] = event.start
              ? event.start.split(':').map(Number)
              : [0, 0];
            const start = new Date(eventDate);
            start.setHours(startHour || 0, startMinute || 0);

            // Parse the end time, default to 23:59 if missing or invalid
            const [endHour, endMinute] = event.end
              ? event.end.split(':').map(Number)
              : [23, 59];
            const end = new Date(eventDate);
            end.setHours(endHour || 23, endMinute || 59);

            // Return the formatted event object
            return {
              title: event.title || 'Unnamed Event',
              start,
              end,
            };
          } catch (error) {
            console.warn(`Skipping event due to error: ${error.message}`, event);
            return null; // Skip invalid events
          }
        }).filter(Boolean); // Remove any null values from the array

        setEvents(formattedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <div className="big-calendar-page">
      <h2>Big Event Calendar</h2>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 800, margin: '50px' }}
      />
    </div>
  );
}

export default BigCalendarPage;
