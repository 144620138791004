// components/BaseUrlManager.js

import React, { useState, useEffect } from 'react';
import BaseUrlList from './BaseUrlList';
import BaseUrlForm from './BaseUrlForm';
import EventUrlManager from './EventUrlManager';
import EventManager from './EventManager';
import useBaseUrls from '../hooks/useBaseUrls';
import useEvents from '../hooks/useEvents';

function BaseUrlManager() {
  const { baseUrls, addBaseUrl, updateBaseUrl, deleteBaseUrl } = useBaseUrls();
  const [selectedBaseUrl, setSelectedBaseUrl] = useState(null);
  const {
    events,
    addEvent,
    updateEvent,
    deleteEvent,
    fetchEventsByBaseUrl,
  } = useEvents();

  // Fetch events whenever the selected base URL changes
  useEffect(() => {
    if (selectedBaseUrl) {
      fetchEventsByBaseUrl(selectedBaseUrl);
    }
  }, [selectedBaseUrl]);

  const handleBaseUrlSelection = (baseUrl) => {
    setSelectedBaseUrl(baseUrl);
  };

  return (
    <div className="base-url-manager">
      <h2>Base URLs</h2>
      <BaseUrlForm onAddBaseUrl={addBaseUrl} />
      <BaseUrlList
        baseUrls={baseUrls}
        onSelectBaseUrl={handleBaseUrlSelection}
        onUpdateBaseUrl={updateBaseUrl}
        onDeleteBaseUrl={deleteBaseUrl}
      />
    </div>
  );
}

export default BaseUrlManager;
