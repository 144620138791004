// services/apiService.js

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = {
  getBaseUrls: async () => {
    const response = await axios.get(`${API_BASE_URL}/base_urls`);
    return response.data;
  },
  addBaseUrl: async (baseUrl) => {
    const response = await axios.post(`${API_BASE_URL}/base_urls`, baseUrl);
    return response.data;
  },
  updateBaseUrl: async (id, baseUrl) => {
    const response = await axios.put(`${API_BASE_URL}/base_urls/${id}`, baseUrl);
    return response.data;
  },
  deleteBaseUrl: async (id) => {
    await axios.delete(`${API_BASE_URL}/base_urls/${id}`);
  },
  getEventUrls: async (baseUrl) => {
    const response = await axios.post(`${API_BASE_URL}/scrape_base_url`, { url: baseUrl });
    return response.data;
  },
  scrapeEventsStream: async (selectedEventUrls, originUrl, onEventReceived) => {
    const urlWithQuery = `${API_BASE_URL}/scrape_events_stream?origin_url=${encodeURIComponent(
      originUrl
    )}`;
    const response = await fetch(urlWithQuery, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(selectedEventUrls),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');

    let done = false;
    while (!done) {
      const { value, done: readerDone } = await reader.read();
      done = readerDone;

      const chunk = decoder.decode(value, { stream: !done });
      const lines = chunk.split('\n\n');

      for (let line of lines) {
        if (line.startsWith('data: ')) {
          const jsonString = line.slice(6);
          try {
            const event = JSON.parse(jsonString);
            onEventReceived(event);
          } catch (e) {
            console.error('Error parsing JSON from stream:', e);
          }
        }
      }
    }
  },
  addEvent: async (eventData) => {
    const response = await axios.post(`${API_BASE_URL}/events`, eventData);
    return response.data;
  },
  updateEvent: async (id, eventData) => {
    const response = await axios.put(`${API_BASE_URL}/events/${id}`, eventData);
    return response.data;
  },
  deleteEvent: async (id) => {
    await axios.delete(`${API_BASE_URL}/events/${id}`);
  },
  deleteEventsByUrls: async (urls) => {
    await axios.delete(`${API_BASE_URL}/events`, { data: { urls } });
  },

  getParserCode: async (baseUrl) => {
    const response = await axios.get(`${API_BASE_URL}/parser_code`, {
      params: { base_url: baseUrl },
    });
    return response.data;
  },

  getEventsByBaseUrl: async (baseUrl, countOnly = false) => {
    const response = await axios.get(`${API_BASE_URL}/events_by_base_url`, {
      params: { base_url: baseUrl, count_only: countOnly },
    });
    return response.data;
  },

  fetchAllEvents: async () => {
    const response = await axios.get(`${API_BASE_URL}/all_events`);
    return response.data;
  },
};

export default apiService;
