// EventList.js

import React from 'react';
import EventItem from './EventItem';

function EventList({ events, onAddEvent, onUpdateEvent, onDeleteEvent }) {
  return (
    <div className="event-list">
      {events.map((event) => (
        <EventItem
          key={event.id}
          event={event}
          onUpdateEvent={onUpdateEvent}
          onDeleteEvent={onDeleteEvent}
        />
      ))}
    </div>
  );
}

export default EventList;
