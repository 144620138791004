// components/BaseUrlForm.js

import React, { useState } from 'react';

function BaseUrlForm({ baseUrl = {}, onAddBaseUrl, onUpdateBaseUrl, onCancel }) {
  const [formData, setFormData] = useState({
    url: baseUrl.url || '',
    selector: baseUrl.selector || '',
    validated: baseUrl.validated || false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (baseUrl.id) {
      onUpdateBaseUrl(formData);
    } else {
      onAddBaseUrl(formData);
      setFormData({ url: '', selector: '', validated: false });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="base-url-form">
      <input
        type="text"
        placeholder="URL"
        value={formData.url}
        onChange={(e) => setFormData({ ...formData, url: e.target.value })}
        required
      />
      <input
        type="text"
        placeholder="Selector"
        value={formData.selector}
        onChange={(e) => setFormData({ ...formData, selector: e.target.value })}
      />
      <span> 
      <input
        id="validated"
        type="checkbox"
        placeholder="Validated"
        checked={formData.validated}
        onChange={(e) => setFormData({ ...formData, validated: e.target.checked })}
      /> 
      { formData.validated ? 'Validated' : 'Test' } 
      </span>
      <button type="submit">{baseUrl.id ? 'Update' : 'Add'}</button>
      {onCancel && <button onClick={onCancel}>Cancel</button>}
    </form>
  );
}

export default BaseUrlForm;
