// EventForm.js

import React, { useState } from 'react';

function EventForm({ event = {}, onAddEvent, onUpdateEvent, onCancel }) {
  const [formData, setFormData] = useState({
    title: event.title || '',
    about: event.about || '',
    start_time: event.start_time || '',
    end_time: event.end_time || '',
    registration_link: event.registration_link || '',
    url: event.url || '',
    origin_url: event.origin_url || '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (event.id) {
      onUpdateEvent(event.id, formData);
    } else {
      onAddEvent(formData);
      setFormData({
        title: '',
        about: '',
        start_time: '',
        end_time: '',
        registration_link: '',
        url: '',
        origin_url: '',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className='event-form'>
          {/* Add inputs for all event fields */}
          <input
            type="text"
            placeholder="Title"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="About"
            value={formData.about}
            onChange={(e) => setFormData({ ...formData, about: e.target.value })}
          />
          <input
            type="text"
            placeholder="Start Time"
            value={formData.start_time}
            onChange={(e) => setFormData({ ...formData, start_time: e.target.value })}
          />
          <input
            type="text"
            placeholder="End Time"
            value={formData.end_time}
            onChange={(e) => setFormData({ ...formData, end_time: e.target.value })}
          />
          <input
            type="text"
            placeholder="Registration Link"
            value={formData.registration_link}
            onChange={(e) => setFormData({ ...formData, registration_link: e.target.value })}
          />
          <input
            type="text"
            placeholder="URL"
            value={formData.url}
            onChange={(e) => setFormData({ ...formData, url: e.target.value })}
          />
          <input
            type="text"
            placeholder="Origin URL"
            value={formData.origin_url}
            onChange={(e) => setFormData({ ...formData, origin_url: e.target.value })}
          />
          {/* End of inputs */}
          <div className='event-form-actions'>
          <button type="submit">{event.id ? 'Update' : 'Add'}</button>
          {onCancel && <button onClick={onCancel}>Cancel</button>}
          </div>
        </form>
  );
}

export default EventForm;
