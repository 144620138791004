// components/EventUrlActions.js

import React, { useState } from 'react';
import apiService from '../services/apiService';

function EventUrlActions({ eventUrls, baseUrl, onEventScraped, onDeleteEventUrls }) {
  const [selectedUrls, setSelectedUrls] = useState([]);

  const handleSelectAll = () => {
    setSelectedUrls([...eventUrls]);
  };

  const handleSelectNone = () => {
    setSelectedUrls([]);
  };

  const handleCheckboxChange = (url) => {
    setSelectedUrls((prev) =>
      prev.includes(url) ? prev.filter((u) => u !== url) : [...prev, url]
    );
  };

  const handleScrape = async () => {
    if (selectedUrls.length === 0) {
      alert('Please select at least one event URL to scrape.');
      return;
    }
    try {
      await apiService.scrapeEventsStream(selectedUrls, baseUrl, onEventScraped);
    } catch (error) {
      console.error('Error scraping events:', error);
    }
  };

  const handleDelete = async () => {
    if (selectedUrls.length === 0) {
      alert('Please select at least one event URL to delete.');
      return;
    }
    await onDeleteEventUrls(selectedUrls);
    setSelectedUrls([]);
  };

  return (
    <div>
      <div className="toolbar">
        <button onClick={handleSelectAll}>Select All</button>
        <button onClick={handleSelectNone}>Select None</button>
        <button onClick={handleScrape}>Scrape Selected</button>
        <button onClick={handleDelete}>Delete Selected</button>
      </div>
      <div className="event-url-list">
        {eventUrls.map((url, index) => (
          <label key={index}>
            <input
              type="checkbox"
              checked={selectedUrls.includes(url)}
              onChange={() => handleCheckboxChange(url)}
            />
            {url}
          </label>
        ))}
      </div>
    </div>
  );
}

export default EventUrlActions;
