// hooks/useEventUrls.js

import { useState } from 'react';
import apiService from '../services/apiService';

function useEventUrls() {
  const [eventUrls, setEventUrls] = useState([]);

  const fetchEventUrls = async (baseUrl) => {
    try {
      const urls = await apiService.getEventUrls(baseUrl);
      setEventUrls(urls);
    } catch (error) {
      console.error('Error fetching event URLs:', error);
    }
  };

  const deleteEventUrls = async (urls) => {
    try {
      await apiService.deleteEventsByUrls(urls);
      setEventUrls((prev) => prev.filter((url) => !urls.includes(url)));
    } catch (error) {
      console.error('Error deleting event URLs:', error);
    }
  };

  return {
    eventUrls,
    fetchEventUrls,
    deleteEventUrls,
  };
}

export default useEventUrls;
