// hooks/useBaseUrls.js

import { useState, useEffect } from 'react';
import apiService from '../services/apiService';

function useBaseUrls() {
  const [baseUrls, setBaseUrls] = useState([]);

  useEffect(() => {
    fetchBaseUrls();
  }, []);

  const fetchBaseUrls = async () => {
    try {
      const data = await apiService.getBaseUrls();
      setBaseUrls(data);
    } catch (error) {
      console.error('Error fetching base URLs:', error);
    }
  };

  const addBaseUrl = async (baseUrl) => {
    try {
      const newBaseUrl = await apiService.addBaseUrl(baseUrl);
      setBaseUrls((prev) => [...prev, newBaseUrl]);
    } catch (error) {
      console.error('Error adding base URL:', error);
    }
  };

  const updateBaseUrl = async (id, updatedBaseUrl) => {
    try {
      const newBaseUrl = await apiService.updateBaseUrl(id, updatedBaseUrl);
      setBaseUrls((prev) => prev.map((b) => (b.id === id ? newBaseUrl : b)));
    } catch (error) {
      console.error('Error updating base URL:', error);
    }
  };

  const deleteBaseUrl = async (id) => {
    try {
      await apiService.deleteBaseUrl(id);
      setBaseUrls((prev) => prev.filter((b) => b.id !== id));
    } catch (error) {
      console.error('Error deleting base URL:', error);
    }
  };

  return {
    baseUrls,
    addBaseUrl,
    updateBaseUrl,
    deleteBaseUrl,
  };
}

export default useBaseUrls;
