// hooks/useEvents.js

import { useState } from 'react';
import apiService from '../services/apiService';

function useEvents() {
  const [events, setEvents] = useState([]);

  const fetchEventsByBaseUrl = async (baseUrl, countOnly = false) => {
    try {
      const data = await apiService.getEventsByBaseUrl(baseUrl);
      setEvents(data);

      // Return the event count if countOnly is true
      return countOnly ? data.length : data;
    } catch (error) {
      console.error(`Error fetching events for base URL ${baseUrl}:`, error);
      return countOnly ? 0 : [];
    }
  };

  const addEvent = async (eventData, noInsert = false) => {
    try {
      if (!noInsert) {
        // Only make the API call if noInsert is false
        const newEvent = await apiService.addEvent(eventData);
        setEvents((prev) => [...prev, newEvent]);
      } else {
        // Just update the local state without making the API call
        setEvents((prev) => [...prev, eventData]);
      }
    } catch (error) {
      console.error('Error adding event:', error);
    }
  };
  

  const updateEvent = async (id, eventData) => {
    try {
      const updatedEvent = await apiService.updateEvent(id, eventData);
      setEvents((prev) => prev.map((e) => (e.id === id ? updatedEvent : e)));
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const deleteEvent = async (id) => {
    try {
      await apiService.deleteEvent(id);
      setEvents((prev) => prev.filter((e) => e.id !== id));
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  return {
    events,
    fetchEventsByBaseUrl,
    addEvent,
    updateEvent,
    deleteEvent,
  };
}

export default useEvents;
